import { t } from '@lingui/macro';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Grid from '@material-ui/core/Grid/Grid';
import { deleteAgent } from 'api/api';
import { sendSearchAction } from 'config/GoogleAnalyticsConfig';
import { getObjectFromParams, handleApi } from 'helper/helper';
import _ from 'lodash';
import CustomAlert from 'MUI_Kit/components/Alert/CustomAlert';
import React from 'react';
import DV from 'variables/DV';
import { DEBOUNCE_TIME } from 'variables/staticValue';
import { getNewQueryParams } from '../../helper/helper';
import Search from '../Search';

class BaseScreen extends React.Component {
  constructor(props) {
    super(props);
    console.log('--constructor BaseScreen', this.props);

    this.state = { alert: null };

    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.renderHeaderAction = this.renderHeaderAction.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.getData = this.getData.bind(this);
    this.renderBaseHeader = this.renderBaseHeader.bind(this);
    this.showContentOnly = false;
    this.showSearch = true;
    this.paramsUrl = getObjectFromParams(window.location.search);

    this.searchFunc = _.debounce((value) => {
      sendSearchAction(value);
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: getNewQueryParams({ q: value }),
      });
    }, DEBOUNCE_TIME);
  }

  setQueryParams = (key, value) => {
    let searchParams = new URLSearchParams(this.props.location.search);
    searchParams.set(key, value);
    return searchParams.toString();
  };

  getQueryParamValue = (key) => {
    return this.getQueryParamObject()?.[key];
  };

  getQueryParamObject = () => {
    try {
      const searchParams = this.props.location.search.substring(1);
      return JSON.parse(
        '{"' +
          decodeURI(searchParams).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
          '"}',
      );
    } catch (e) {
      return {};
    }
  };

  getQueryParamString = () => {
    return this.props.location.search;
  };

  handleParamsSearch = (params) => params;

  handleTextChange = (name) => (event) => {
    const value = event?.target?.value ?? '';
    this.setState({
      [name]: value,
    });
  };

  handleSubmitSearch(value) {
    sendSearchAction(value);
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: getNewQueryParams({ q: value }),
    });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event && event.target ? event.target.value : event,
    });
  };

  componentDidMount() {
    // this.lastTop = 0;
    this.prevScrollpos = this.containerContent?.scrollTop;
    //console.log('componentDidMount BaseScreen', this.props.getData);
    DV.hideHeaderAction(false);
  }

  handleScroll = () => {
    // const lastScrollY = this.containerContent.scrollTop;
    // if (this.containerContent.scrollHeight - this.containerContent.scrollTop !== this.containerContent.clientHeight)
    //     this.hideHeaderAction(this.lastTop - lastScrollY);
    // this.lastTop = lastScrollY;
    this.currentScrollPos = this.containerContent?.scrollTop;
    //console.log('handleScroll', this.prevScrollpos, this.currentScrollPos)
    DV.hideHeaderAction(this.prevScrollpos <= this.currentScrollPos);
    this.prevScrollpos = this.currentScrollPos;
  };

  redirectTo = (pathname, state = null, search = '') => {
    this.props.history.push({
      pathname: pathname,
      search,
      state,
    });
  };

  redirectToModal = (pathname, state = null, search = '') => {
    this.props.history.push({
      pathname: pathname,
      search,
      state: { isModal: true, ...state },
    });
  };

  getDataFromRouter = () => (this.props.location && this.props.location.state) || {};

  render() {
    const { inModal } = this.props;
    if (this.showContentOnly || inModal) return this.renderContent();

    DV.updateHeaderAction(this.renderBaseHeader());
    return (
      <div
        onScroll={DV.withMobileDialog ? this.handleScroll : undefined}
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          overflow: 'scroll',
          height: '100%',
        }}
        ref={(ref) => (this.containerContent = ref)}>
        {this.state.alert}
        {this.renderContent()}
      </div>
    );
  }

  getData() {}

  renderBody() {}

  renderContent() {}

  renderBaseHeader() {

    return (
      <Grid container justify="space-between" className="header-action-view">
        {this.showSearch
          ? [
              <Grid item xs={12} sm={6} className="flex-center-row">
                <Search
                  onSubmit={this.handleSubmitSearch}
                  defaultValue={this.state.keyword || ''}
                />
              </Grid>,
              <Grid item className="flex-end-row flex-center-row-xs" xs={12} sm={6}>
                {this.renderHeaderAction()}
              </Grid>,
            ]
          : <div className="flex flex-row justify-end w-full">
            {
              this.renderHeaderAction()}
          </div>}
      </Grid>
    );
  }

  renderHeaderAction() {
    return null;
  }

  renderScrollView = () => {
    return (
      <div
        style={{ float: 'left', clear: 'both' }}
        ref={(el) => {
          this.containerScroll = el;
        }}
      />
    );
  };

  scrollToBottom = () => {
    this.containerScroll && this.containerScroll.scrollIntoView({ behavior: 'smooth' });
  };

  warningWithConfirmMessage = (callbackFunc, ...params) => {
    let classes = DV.classes;
    this.setState({
      alert: (
        <CustomAlert
          warning
          title={t`Bạn có muốn xóa?`}
          onConfirm={async () => {
            const result = await callbackFunc(...params);
            if (!result) return;

            const { message, isSuccess } = result;
            if (isSuccess) {
              this.showAlertDeleteSuccessful(message);
            } else {
              this.showErrorMessage(message);
            }
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.danger}
          cancelBtnCssClass={classes.button}
          confirmBtnText={t`Xóa`}
          cancelBtnText={t`Hủy bỏ`}
          showCancel>
          {t`Bạn không thể phục hồi sau khi đã nhấn nút xóa!`}
        </CustomAlert>
      ),
    });
  };

  showErrorMessage = (message = '') => {
    let classes = DV.classes;
    this.setState({
      alert: (
        <CustomAlert
          danger
          title={t`Có lỗi xảy ra`}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          hideAlert={this.hideAlert}>
          <div>{message}</div>
          <div>{t`Vui lòng nhấn Ctrl+F5 hoặc Cmd+F5 để tải lại trang`}</div>
        </CustomAlert>
      ),
    });
  };

  showWarningMessage = (message) => {
    let classes = DV.classes;
    this.setState({
      alert: (
        <CustomAlert
          warning
          title={t`Lưu ý`}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          hideAlert={this.hideAlert}>
          {message}
        </CustomAlert>
      ),
    });
  };

  showLoadingMessage = () => {
    let classes = DV.classes;
    this.showloading = true;
    this.setState({
      alert: (
        <CustomAlert
          custom
          customIcon={<CircularProgress className={classes.progress} color="secondary" size={88} />}
          title={t`Đang xử lý`}
          showConfirm={false}
          hideAlert={this.hideAlert}>
          {t`Vui lòng chờ trong giây lát`}
        </CustomAlert>
      ),
    });
  };

  showAlertDeleteSuccessful = (message) => {
    this.setState({
      alert: (
        <CustomAlert
          success
          title={t`Đã xóa`}
          closeOnClickOutside={false}
          showConfirm={false}
          hideAlert={this.hideAlert}>
          {message || t`Đã xóa thành công`}
        </CustomAlert>
      ),
    });
    setTimeout(() => {
      this.hideAlert();
    }, 1000);
  };

  hideAlert = () => {
    this.showloading = false;
    this.setState({ alert: null });
  };
}

export default BaseScreen;
