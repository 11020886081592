import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import { updateCompany } from 'api/api.js';
import AddressInput from 'component/AddressInput/AddressInput.js';
import BaseModal from 'component/BaseComponent/BaseModal';
import { setState } from 'config/Core.js';
import { trans } from 'config/LinguiConfig';
import { getFormData, handleApi, validateEmail, validatePhone } from 'helper/helper.js';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import ImageUpload from 'MUI_Kit/components/CustomUpload/ImageUpload.jsx';
import React from 'react';
import Routes from 'router/routes.js';
import DV from 'variables/DV';

class ChangeCompanyInfoModal extends BaseModal {
  constructor(props) {
    super(props);
    const {
      name,
      phone,
      address,
      province,
      district,
      ward,
      logo,
      license_image,
      email,
      description,
    } = DV.company;
    this.state = {
      open: true,
      loading: false,
      title: t`Thay đổi thông tin công ty`,
      name,
      currency: DV.currency,
      phone,
      logo: { url: logo, file: null },
      licenseImage: { url: license_image, file: null },
      address,
      province,
      district,
      ward,
      email,
      description,
      ...this.getDataFromRouter(),
    };
    this.goPathWhenClose = Routes.settings.path;
  }

  handleImageChange = (name) => async (file, url) => {
    await setState(this)({
      [name]: { url, file },
    });
  };

  update = async (data) => {
    this.setState({ loading: true });
    let { error } = await handleApi(updateCompany(data));
    this.setState({ loading: false });
    if (error) {
      DV.showNotify(error, 'danger');
      return;
    }
    DV.showNotify(t`Cập nhật thành công`);
    // setTimeout(() => window.location.href = Routes.settings.path, 500);
  };

  renderBody() {
    let { name, logo, licenseImage, phone, address, province, district, ward, email, description } =
      this.state;
    return (
      <Grid container>
        <Grid container justify="center" xs={5}>
          <Grid item xs={12}>
            <div className="flex-center-col">
              <h5>{t`Logo`}</h5>
              <ImageUpload
                ref={(ref) => (this.imageUpload = ref)}
                onChange={this.handleImageChange('logo')}
                imageSubmitAddress={logo.url}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="flex-center-col">
              <h5>{t`Con dấu`}</h5>
              <ImageUpload
                ref={(ref) => (this.imageUpload = ref)}
                onChange={this.handleImageChange('licenseImage')}
                imageSubmitAddress={licenseImage.url}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid item xs={12}>
            <CustomInput
              labelText={t`Tên công ty`}
              inputProps={{
                value: name || '',
                placeholder: t`Tên công ty`,
                onChange: this.handleChange('name'),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              labelText={t`Số điện thoại`}
              inputProps={{
                value: phone || '',
                placeholder: t`Số điện thoại`,
                onChange: this.handleChange('phone'),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              labelText={'Email'}
              inputProps={{
                value: email || '',
                placeholder: 'Email',
                onChange: this.handleChange('email'),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AddressInput
              ref={(ref) => (this.addressInput = ref)}
              data={{ address, province, district, ward }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              labelText={t`Mô tả`}
              inputProps={{
                value: description || '',
                placeholder: t`Mô tả`,
                onChange: this.handleChange('description'),
              }}
            />
          </Grid>
        </Grid>

        {/*<Grid item xs={12} md={6}>*/}
        {/*    <div style={{height: 4}}/>*/}
        {/*    <CustomSelect*/}
        {/*        label={'Đơn vị tiền tệ'}*/}
        {/*        data={['VND', 'USD']}*/}
        {/*        value={currency || ''}*/}
        {/*        onChange={this.handleChange('currency')}*/}
        {/*        helpText={(t`Ví dụ: `) + ' ' + toCurrency(200000, currency)}*/}
        {/*    />*/}
        {/*</Grid>*/}
      </Grid>
    );
  }

  handleButton = async () => {
    const { name, phone, logo, licenseImage, email, description } = this.state;
    if (!this.validate()) return;
    let payload = {
      name,
      phone,
      email,
      description,
      ...this.addressInput.getData(),
      logo: logo.file,
      license_image: licenseImage.file,
    };
    let postData = getFormData(payload);
    await this.update(postData);
  };

  validate = () => {
    const { name, phone, email } = this.state;
    if (name.length < 3) {
      DV.showNotify(t`Tên công ty phải có ít nhất 3 ký tự.`, 'danger');
      return false;
    }
    if (!validatePhone(phone)) {
      DV.showNotify(t`Số điện thoại không hợp lệ`, 'danger');
      return false;
    }
    if (!validateEmail(email)) {
      DV.showNotify(t`Email công ty không hợp lệ`, 'danger');
      return false;
    }
    return true;
  };

  renderFooter() {
    return (
      <Button loading={this.state.loading} onClick={this.handleButton} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }
}

export default ChangeCompanyInfoModal;
