import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import BaseModal from 'component/BaseComponent/BaseModal';
import { handleApi } from 'helper/helper';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import React from 'react';
import Routes from 'router/routes';
import DV from 'variables/DV';
import { connect } from 'react-redux';
import { addTelegram, updateTelegram } from '../../../../redux/action/apiAction';

class AddTelegramModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      open: true,
      id: null,
      title: this.getTitle(),
      ...this.getDataFromRouter(),
    };

    this.goPathWhenClose = Routes.channel.path;
  }

  getTitle() {
    const { id } = this.getDataFromRouter();
    return id ? t`Cập nhật telegram ${id}` : t`Thêm telegram`;
  }

  renderBody() {
    const { bot_token } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInput
            labelText={t`Bot token`}
            inputProps={{
              value: bot_token || '',
              onChange: this.handleChange('bot_token'),
            }}
          />
        </Grid>
      </Grid>
    );
  }

  handleSubmit = async () => {
    const { id } = this.state;
    this.setState({ loading: true });
    if (id) await this.update();
    else await this.add();
    this.setState({ loading: false });
  };

  renderFooter() {
    return (
      <Button loading={this.props.loading} onClick={this.handleSubmit} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }

  add = async () => {
    const { bot_token } = this.state;
    let { error } = await this.props.dispatch(addTelegram({ bot_token }));

    if (!error) {
      this.close(true);
      DV.showNotify(t`Thêm telegram thành công`);
    } else {
      let { is_shared_doopage_to_facebook } = DV.company;
      if (error.includes(t`nâng cấp gói cước`) && !is_shared_doopage_to_facebook)
        DV.showWarningLimitModal();
      else DV.showNotify(error, 'danger');
    }
  };

  update = async () => {
    const { id, bot_token } = this.state;
    let { error } = await this.props.dispatch(
      updateTelegram(id, {
        bot_token,
      }),
    );

    if (!error) {
      DV.url = window.location.pathname;
      this.close(true);
      DV.showNotify(t`Cập nhật telegram thành công`);
    } else DV.showNotify(error, 'danger');
  };
}

export default connect()(AddTelegramModal);
