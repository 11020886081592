import { t } from "@lingui/macro";
import get from "lodash/get";
import clone from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import truncate from "lodash/truncate";

import moment from "moment";
import queryString from "query-string";
import React from "react";
import { createSelector } from "reselect";
import { version } from "../../package.json";
import { useLocation } from "react-router-dom";
import { defaultLocaleOptions } from "@doopage/react-ui-kit";
import axios from "axios";
import LocalStorage from "../config/LocalStorage";
import { CLIENT_BASE_URL, ZALO_REDIRECT_URL } from "../config/Env";
import { authorizationZaloOA } from "../api/api";

// make setState as promise, await setState(this)(stateObject)
export const setState = (instance) => (newState) =>
  new Promise((resolve) => instance.setState(newState, resolve));

// get params from url
export const getParamsUrl = (defaultParams = {}) => {
  const params = queryString.parse(window.location.search, {
    arrayFormat: "comma"
  });

  if (!isEmpty(params)) return params;
  return defaultParams;
};

// parse object to query url
export const setParamsUrl = (obj) => queryString.stringify(obj, { arrayFormat: "comma" });

// set placeholder for search input react table
export const addFilterPlaceholder = () => {
  const filters = document.querySelectorAll("div.rt-th > input[type=text]");
  Object.keys(filters).forEach((key) => {
    const filter = filters[key];
    filter.placeholder = t`Tìm...`;
    filter.setAttribute("label", "Filter input");
    filter.setAttribute("aria-label", "label");
    filter.setAttribute("aria-invalid", "false");
  });
};

// get value from object
export const getValue =
  (path, defaultValue = undefined) =>
    (object) => {
      // carefully, lodash get may return null
      return get(object, path, defaultValue) || defaultValue;
    };

export const getArray =
  (path, defaultValue = []) =>
    (object) => {
      const arr = get(object, path, defaultValue);
      if (Array.isArray(arr)) return arr;
      return [arr];
    };

// get number from object
export const getNumber =
  (path, defaultValue = 0) =>
    (object) => {
      try {
        return parseInt(get(object, path, defaultValue));
      } catch (e) {
        console.warn(`Can't get number from ${path}`);
        return defaultValue;
      }
    };

// get value from object, use reselect to memory value
export const selectValue = (path, defaultValue = {}) =>
  createSelector(getValue(path, defaultValue), (data) => data);

export const trimValue = (value) => trim(value);

export const getPaging = (paging, syncWithUrl = true) => {
  if (!isEmpty(paging))
    return {
      page: getNumber("current_page", 1)(paging) - 1, //react table set page index from 0, server from 1
      pages: getNumber("total_pages")(paging),
      pageSize: getNumber("per_page", 20)(paging),
      total: getNumber("total", 0)(paging)
    };

  //paging empty => get paging from url
  if (syncWithUrl) {
    const { page, limit } = getParamsUrl();

    return {
      page: getNumber("page", 1)({ page }) - 1, //react table set page index from 0, server from 1
      pageSize: getNumber("limit", 20)({ limit }),
      pages: getNumber("page", 1)({ page }),
      total: 0
    };
  }

  return {};
};

export const getFiltered = (defaultParams, syncWithUrl = true) => {
  const { page, limit, ...filters } = getParamsUrl();
  const { page: a, limit: b, ...defaultFilter } = defaultParams;
  if (isEmpty(filters)) return getFilters(defaultFilter);
  if (syncWithUrl) return getFilters(filters);
  return null;
};

// get filter from filters and parse to object use with react table
export const getFilters = (filters) => {
  return Object.keys(filters).map((key) => {
    return {
      id: key,
      value: filters[key]
    };
  });
};

// truncate text
export const getTruncate = (text, length = 30) => {
  return truncate(text, { length });
};

// format date time
export function formatDateTime(datetime, format = "DD-MM-YYYY") {
  if (!datetime) return "";

  let date = datetime.constructor === String ? moment(datetime, format) : datetime;

  if (date) {
    const a = moment(date).format(format).toString();
    console.log("formatDateTime", date);
    if (a.includes("date")) return datetime;
    return a;
  }

  return "";
}

export const formatUnixTime = (value, format = "DD/MM/YYYY") => {
  if (!value || value < 0) return "";

  return moment.unix(value).format(format);
};

// format number to vnd
export const formatVND = (number) => {
  if (!number && number !== 0) return "";

  try {
    const formatter = new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "VND"
    });

    return formatter.format(parseInt(number || 0));
  } catch (e) {
    return number;
  }
};

// return boolean check should update table
export const shouldUpdateTable = (search, params, defaultParms) => {
  const isDiffParams = search !== `?${setParamsUrl(params)}`;
  const isDiffDefaultParams =
    search === "" ? `?${setParamsUrl(params)}` !== `?${setParamsUrl(defaultParms)}` : true;

  return isDiffParams && isDiffDefaultParams;
};

// fetch api
export const handleApi = async (func) => {
  if (!func) return { success: false };
  return func()
  .then((data) => {
    return { success: true, ...data };
  })
  .catch((error) => {
    return { success: false, error };
  });
};

export const appVersion = version;

export const randomNumber = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const useRouterQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const removePunctuation = (str = "") => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  return str;
};

export const getLocale = (id) => {
  if (id === "default") return { id: "default", name: "Mặc định", allowRemove: false };
  return defaultLocaleOptions.find((item) => item.id === id);
};

export const removeObjectKey = (obj, key) => {
  const cloneObj = clone(obj);
  for (const property in cloneObj) {
    if (typeof cloneObj[property] === "object") {
      delete cloneObj.property;
      cloneObj[property] = removeObjectKey(cloneObj[property], key);
    } else {
      if (property === key) {
        delete cloneObj[property];
      }
    }
  }
  return cloneObj;
};

export const openZaloOAPage = async ({ onSuccess, onError }) => {
  let windowWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
  let windowHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
  let redirect_uri = `${CLIENT_BASE_URL}/zalo-oa-authentication-success.html`;
  let backend_redirect = `${ZALO_REDIRECT_URL}?redirect=${encodeURIComponent(redirect_uri)}`;

  let { authorization_url, error } = await handleApi(authorizationZaloOA({ redirect_uri: backend_redirect }));

  if (!!error) {
    onError?.(error);
    return;
  }

  let height = 720;
  let width = 500;
  let left = (windowWidth - width) / 2;
  let top = (windowHeight - height) / 2;
  window.__success__ = (data, popup) => {
    if (data.code && data.code_challenge && data.oa_id) {
      popup.close();
      delete window.__success__;
      onSuccess?.(data);
    }
  };

  let popup = window.open(authorization_url, "_blank", `height=${height},width=${width},left=${left},top=${top},menubar=0,status=0,titlebar=0,scrollbars=0`);
  if (!popup.focus) popup.focus();
};

export const getPermanentIP = async () => {
  const ip = LocalStorage.getPermanentIP();
  if (ip) return ip;

  try {
    const result = await axios.get("https://ipinfo.io?token=8ea747052f8e40");
    LocalStorage.setPermanentIP(result.data);

    return result.data;
  } catch (e) {
    console.log(e);
  }
  return {};
};
