import {t} from '@lingui/macro';
import React from 'react';
import Routes from 'router/routes.js';
import DV from 'variables/DV.js';
import {LIST_DOMAIN} from 'variables/staticValue.js';
import Button from '../../MUI_Kit/components/CustomButtons/Button';
import BaseModal from '../BaseComponent/BaseModal';
import {handleApi} from 'helper/helper';
import {setState} from 'config/Core';
import {addZalo, scanZalo} from 'api/api';
import Typography from '@material-ui/core/Typography/Typography';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Paper from '@material-ui/core/Paper/Paper';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar/ListItemAvatar';

const documentHref = '#';
const proxyHost = process.env.REACT_APP_PROXY_HOST;
const proxyPort = process.env.REACT_APP_PROXY_PORT;

class ZaloModal extends BaseModal {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      accounts: [],
      password: Math.random().toString(36).slice(2).slice(0, 5),
      scanTimer: null,
      ...this.getDataFromRouter(),
    };
    this.goPathWhenClose = Routes.channel.path;
  }

  getTitle() {
    return t`Thêm Zalo cá nhân`;
  }

  add = async (session_id) => {
    await setState(this)({loading: true});
    let {success, error} = await handleApi(addZalo({session_id}));
    await setState(this)({loading: false});
    if (success) {
      this.close(true);
      // DV.showNotify(
      //   (t`Tài khoản của bạn đang được kết nối vui lòng đợi trong ít phút để sử dụng.`),
      // );
    } else DV.showNotify(error, 'danger');
  };

  handleClick = ({id}) => {
    return () => {
      return this.add(id);
    };
  };

  renderBody() {
    const {accounts, password} = this.state;

    return (<>
      <Typography>
        {t`Bước 1`}: {t`Cài đặt trình duyệt sử dụng proxy với cấu hình như sau`} (<a href={documentHref} style={{textDecoration: 'underline'}}>{t`xem hướng dẫn`}</a>):<br/>
        <ul style={{listStyleType: 'disc', marginLeft: 25}}>
          <CopyToClipboard text={proxyHost}>
            <li>{t`Địa chỉ`}: <Tooltip title={t`Nhấn để copy`}><span>{proxyHost}</span></Tooltip></li>
          </CopyToClipboard>
          <CopyToClipboard text={proxyPort}>
            <li>{t`Port`}: <Tooltip title={t`Nhấn để copy`}><span>{proxyPort}</span></Tooltip></li>
          </CopyToClipboard>
          <CopyToClipboard text={DV.company.id}>
            <li>{t`Tên đăng nhập`}: <Tooltip title={t`Nhấn để copy`}><span>{DV.company.id}</span></Tooltip></li>
          </CopyToClipboard>
          <CopyToClipboard text={password}>
            <li>{t`Mật khẩu`}: <Tooltip title={t`Một chuỗi ngẫu nhiên, nhấn để copy`}><span>{password}</span></Tooltip></li>
          </CopyToClipboard>
        </ul>
        {t`Bước 2`}: {t`Truy cập vào `} <a href="https://facebook.com/"
                                           target="_blank">https://facebook.com/</a> {t`và đăng nhập vào Facebook (nếu chưa đăng nhập). Hệ thống sẽ tự động nhận diện tài khoản Facebook trong từ 1 đến 10 phút.`}<br/>
      </Typography>
      <br/>
      {accounts.length ? (<Paper style={{width: 300, margin: '0 auto'}}>
        <List>
          {accounts.map((item, i) => <ListItem key={i} button divider={i < accounts.length - 1} onClick={this.handleClick(accounts[i])}>
            <ListItemAvatar>
              <img src={item.avatar} style={{borderRadius: '100%', height: '50px', width: '50px'}} alt={'avatar'}/>
            </ListItemAvatar>
            <ListItemText primary={item.name} secondary={item.phone_number ?? item.user_id}/>
          </ListItem>)}
        </List>
      </Paper>) : (<Paper style={{height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Typography style={{color: '#a8a8a8'}}>Chưa phát hiện tài khoản nào...</Typography>
      </Paper>)
      }
    </>);
  }

  renderFooter() {
    const {loading} = this.state;
    return [
      <Button
        href={LIST_DOMAIN[DV.partner].guide.personal}
        color="info"
        simple
        target="_blank"
        rel="noopener noreferrer">
        {t`Hướng dẫn`}
      </Button>,
      <Button onClick={this.handleSubmit} color="info" loading={loading}>
        {t`Hoàn tất`}
      </Button>,
    ];
  }

  async componentDidMount() {
    const scanTimer = setInterval(() => this.scan(), 15 * 1000);
    this.setState({scanTimer});
    this.scan();
  }

  componentWillUnmount() {
    const {scanTimer} = this.state;
    if (scanTimer) {
      clearInterval(scanTimer);
    }
  }

  async scan() {
    const {data} = await handleApi(scanZalo());
    if (!data) {
      DV.showNotify(t`Có lỗi xảy ra khi lấy danh sách tài khoản`, 'danger');
    }
    await this.setState({accounts: data});
  }
}

export default ZaloModal;
